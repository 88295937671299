var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-family":"'IBM Plex Sans Thai'","font-style":"normal","font-weight":"600","font-size":"24px","line-height":"40px"}},[_vm._v(" ข่าวประกาศ ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":['ข่าวการจัดซื้อจัดจ้าง', 'งบประมาณ', 'ขายทอดตลาด'],"placeholder":"เลือกหมวดหมู่","dense":"","outlined":""},on:{"change":function($event){return _vm.getAllbanner(_vm.selected_type)}},model:{value:(_vm.selected_type),callback:function ($$v) {_vm.selected_type=$$v},expression:"selected_type"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"#2AB3A3","dark":""},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v("เพิ่มข้อมูลข่าวประกาศ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBrand,"items":_vm.items,"search":_vm.search,"disable-pagination":"","hide-default-footer":"","no-data-text":"ไม่มีข้อมูล..","loading-text":"กำลังโหลดข้อมูล"},scopedSlots:_vm._u([{key:"item.announce_name",fn:function(ref){
var item = ref.item;
return [(item.announce_name.length < 52)?_c('span',[_vm._v(_vm._s(item.announce_name))]):_c('span',[_vm._v(_vm._s(item.announce_name.substr(0, 52))+"...")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.updatedAt.substr(0, 10)))])]}},{key:"item.announce_type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#f0c802"}},[_vm._v(_vm._s(item.announce_type))])]}},{key:"item.announce_pdf",fn:function(ref){
var item = ref.item;
return [_c('u',{on:{"click":function($event){return _vm.opennewtab(item.announce_pdf)}}},[_vm._v("link...")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateBanner(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteBanner(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }